import { LOAD_DELIVERY_BOY } from "./constants";

const initialState = {
  loading: false,
  deliveryBoy: {},
  deliveryDetails: {},
  error: {},
};

export default function settlement(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_DELIVERY_BOY:
      return {
        ...state,
        loading: false,
        deliveryBoy: payload.delivery,
        deliveryDetails: payload.data,
      };

    default:
      return state;
  }
}
