import {
  GET_RESTAURANT_PRODUCTS,
  GET_RESTAURANTS,
  RESTAURANT_ERROR,
  LOADED,
  LOADING,
} from "./constants";

const initialState = {
  loading: false,
  restaurantCount: 0,
  restaurants: [],
  products: [],
  error: {},
};

export default function restaurant(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESTAURANTS:
      return {
        ...state,
        loading: false,
        restaurants: payload.data,
        restaurantCount: payload.count,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };

    case RESTAURANT_ERROR:
      return {
        ...state,
        error: payload,
      };

    case GET_RESTAURANT_PRODUCTS:
      return {
        ...state,
        products: payload.data,
      };

    default:
      return state;
  }
}
