import {
  GET_BRANCHES,
  BRANCH_LOADING,
  BRANCH_ERROR,
  LOADING,
  LOADED,
  GROC_LOADING,
  GROC_LOADED,
  LIST_LOADING,
  LIST_LOADED,
  BANNER_LISTING,
  BANNER_SINGLE_LIST,
  GET_PROMOCODES,
} from "./Constants";

const initialState = {
  loading: false,
  grocLoading: false,
  listLoading: false,
  promocodes: [],
  count: 0,
  branches: [],
  listing: [],
  singleList: {},
  error: {},
};

export default function branch(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANCHES:
      return {
        ...state,
        loading: false,
        count: payload.count,
        branches: payload.data,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };
    case GROC_LOADING:
      return {
        ...state,
        grocLoading: true,
      };
    case GROC_LOADED:
      return {
        ...state,
        grocLoading: false,
      };
    case LIST_LOADING:
      return {
        ...state,
        listLoading: true,
      };
    case LIST_LOADED:
      return {
        ...state,
        listLoading: false,
      };
    case BRANCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BRANCH_ERROR:
      return {
        ...state,
        error: payload,
      };
    case BANNER_LISTING:
      return {
        ...state,
        listing: payload.data,
      };
    case BANNER_SINGLE_LIST:
      return {
        ...state,
        singleList: payload,
      };
    case GET_PROMOCODES:
      return {
        ...state,
        promocodes: payload,
      };
    default:
      return state;
  }
}
