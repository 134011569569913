import {
  GET_CATEGORY,
  GET_LIST_STORES,
  GET_LIST_PRODUCTS,
  LIST_LOADING,
  GET_LIST_CONTACTS,
} from "./constants";

const initialState = {
  loading: false,
  error: {},
  categories: [],
  storeCount: 0,
  stores: [],
  contactCount: 0,
  contacts: [],
  productCount: 0,
  products: [],
};

export default function listing(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORY:
      return {
        ...state,
        loading: false,
        categories: payload.data,
      };

    case GET_LIST_STORES:
      return {
        ...state,
        loading: false,
        storeCount: payload.count,
        stores: payload.data,
      };

    case GET_LIST_PRODUCTS:
      return {
        ...state,
        loading: false,
        productCount: payload.count,
        products: payload.data,
      };

    case GET_LIST_CONTACTS:
      return {
        ...state,
        loading: false,
        contactCount: payload.count,
        contacts: payload.data,
      };

    case LIST_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
}
