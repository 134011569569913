import { Redirect } from "react-router-dom";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import io from "socket.io-client";
// import axios from 'axios'
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  GET_USERS,
  GET_ME,
} from "./constants";
import { ENDPOINT } from "src/utils/config";
import { getLiveOrders } from "../Order/action";
import alertSound from "../../assets/sounds/notify.mp3";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/me");
    if (res.data?.data?.role === "branch") {
      const branchMe = await api.get("/branch/me");
      const socket = io(ENDPOINT);
      const audio = new Audio(alertSound);

      socket.emit("join", `branch_${branchMe?.data?.data?._id}`);
      socket.on("message", (data) => {
        console.log(data);
      });

      socket.on("newOrder", (data) => {
        dispatch(setAlert("New Order", "success"));
        audio.play();
        dispatch(getLiveOrders());
        // console.log(data);
      });

      socket.on("orderUpdate", (data) => {
        dispatch(setAlert("Order Upddated", "success"));
        audio.play();
        dispatch(getLiveOrders());
        // console.log(data);
      });
    }

    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (username, password) => async (dispatch) => {
  const body = { username, password };

  try {
    const res = await api.post("/auth/login", body);
    // console.log(res.data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch(setAlert("Please enter valid credentials", "info"));
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout
export const logout = () => (dispatch) => {
  <Redirect to="/" />;
  dispatch({
    type: LOGOUT,
  });
};

// Get All users
export const getUsers = () => async (dispatch) => {
  try {
    const res = await api.get("/users");
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Add user
export const addUser = (data) => async (dispatch) => {
  try {
    const res = await api.post("/users", data);

    dispatch(getUsers());
    dispatch(setAlert("User added", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Edit user
export const editUser = (data, id) => async (dispatch) => {
  try {
    const res = await api.put(`/users/${id}`, data);
    dispatch(getUsers());
    dispatch(setAlert("User details updated", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Edit user
export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/users/${id}`);
    dispatch(setAlert("User Deleted", "danger"));
    dispatch(getUsers());
  } catch (err) {
    console.log(err);
  }
};

// Get me
export const getMe = () => async (dispatch) => {
  try {
    const res = await api.get(`/branch/me`);
    dispatch({
      type: GET_ME,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
