import {
  GET_ACCOUNT_TOTAL,
  GET_DELIVERY_SETTLEMENTS,
  GET_DELIVERY_PAYMENTS,
  GET_REST_ACCOUNT_TOTAL,
  GET_REST_SETTLEMENTS,
  GET_REST_PAYMENTS,
} from "./constants";

const initialState = {
  loading: false,
  totalPaymentAmount: {}, //total payment amount for delivery boy
  totalRestPaymentAmount: {},
  settlements: [], //settlement list for delivery boy
  payments: [], //payment list for delivery boy
  restSettlements: [],
  restPayments: [],
};

export default function payment(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACCOUNT_TOTAL:
      return {
        ...state,
        loading: false,
        totalPaymentAmount: payload.data,
      };

    case GET_DELIVERY_SETTLEMENTS:
      return {
        ...state,
        loading: false,
        settlements: payload.data,
      };

    case GET_DELIVERY_PAYMENTS:
      return {
        ...state,
        loading: false,
        payments: payload.data,
      };

    case GET_REST_ACCOUNT_TOTAL:
      return {
        ...state,
        loading: false,
        totalRestPaymentAmount: payload.data,
      };

    case GET_REST_SETTLEMENTS:
      return {
        ...state,
        loading: false,
        restSettlements: payload.data,
      };

    case GET_REST_PAYMENTS:
      return {
        ...state,
        loading: false,
        restPayments: payload.data,
      };

    default:
      return state;
  }
}
