import { GET_DELIVERY_BOYS, DELIVERY_LOADING } from "./constants";

const initialState = {
  loading: false,
  count: 0,
  deliveryboys: [],
  error: {},
};

export default function deliveryboy(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DELIVERY_BOYS:
      return {
        ...state,
        loading: false,
        deliveryboys: payload.data,
        count: payload.count,
      };

    case DELIVERY_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
