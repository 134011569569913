import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";
import branch from "./modules/Branch/Reducer";
import groceryStore from "./modules/GroceryStore/reducer";
import restaurant from "./modules/Restaurant/reducer";
import listing from "./modules/Listing/reducer";
import order from "./modules/Order/reducer";
import deliveryboy from "./modules/Deliveryboy/reducer";
import settlement from "./modules/Settlement/reducer";
import payment from "./modules/Payment/reducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
  branch,
  groceryStore,
  restaurant,
  listing,
  order,
  deliveryboy,
  settlement,
  payment,
});
