import { GET_ORDERS, GET_LIVE_ORDERS } from "./constants";

const initialState = {
  loading: false,
  orderCount: 0,
  orders: [],
  liveOrders: [],
  error: {},
};

export default function order(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: payload.data,
        orderCount: payload.count,
      };

    case GET_LIVE_ORDERS:
      return {
        ...state,
        liveOrders: payload.data,
      };

    default:
      return state;
  }
}
