import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import { GET_ORDERS, GET_LIVE_ORDERS } from "./constants";

//Get orders
export const getOrders = (data) => async (dispatch) => {
  try {
    const res = await api.post("/order/branch", data);
    dispatch({
      type: GET_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

//Get live orders
export const getLiveOrders = () => async (dispatch) => {
  try {
    const res = await api.get("/order/branchLive");
    dispatch({
      type: GET_LIVE_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const assingDeliveryboy =
  (deliveryBoyId, orderId) => async (dispatch) => {
    try {
      const res = await api.put(`/order/assign`, {
        id: orderId,
        deliveryBoy: deliveryBoyId,
      });
      if (res.data.success) {
        dispatch(setAlert("Delivery boy assigned", "success"));
        dispatch(getLiveOrders());
      } else {
        dispatch(setAlert("Error while assigning", "danger"));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const acceptGroceryOrder = (orderId, status) => async (dispatch) => {
  try {
    const res = await api.put(`/order/acceptGrocery`, {
      id: orderId,
      orderStatus: status,
    });
    if (res.data.success) {
      dispatch(setAlert("Order Updated", "success"));
      dispatch(getLiveOrders());
    } else {
      dispatch(setAlert("Error while updating", "danger"));
    }
  } catch (err) {
    console.log(err);
  }
};
