import { GET_PRODUCTS } from "./constants";

const initialState = {
  loading: false,
  count: 0,
  products: [],
  error: {},
};

export default function groceryStore(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: payload.data,
        count: payload.count,
      };

    default:
      return state;
  }
}
