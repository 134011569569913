export const GET_BRANCHES = "GET_BRANCHES";
export const BRANCH_LOADING = "BRANCH_LOADING";
export const BRANCH_ERROR = "BRANCH_ERROR";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const GROC_LOADING = "GROC_LOADING";
export const GROC_LOADED = "GROC_LOADED";
export const LIST_LOADING = "LIST_LOADING";
export const LIST_LOADED = "LIST_LOADED";
export const BANNER_LISTING = "BANNER_LISTING";
export const BANNER_SINGLE_LIST = "BANNER_SINGLE_LIST";
export const GET_PROMOCODES = "GET_PROMOCODES";
